import { createContext, useReducer } from 'react';
import { BULK_MODE, DEFAULT_GRID_SIZE, GRID_SIZE } from '../constants';

const initialState = {
  bulkMode:
    (localStorage.getItem(BULK_MODE) === 'true' ? true : false) ?? false,
  gridSize:
    localStorage.getItem(DEFAULT_GRID_SIZE) !== null
      ? Number(localStorage.getItem(DEFAULT_GRID_SIZE))
      : GRID_SIZE.GRID_2X2,
  tabIndex: 0,
};

export const DataContext = createContext();
export const DataDispatchContext = createContext();

export const ACTIONS = {
  TOGGLE_BULK_MODE: 'TOGGLE_BULK_MODE',
  TOGGLE_GRID_SIZE: 'TOGGLE_GRID_SIZE',
  NAVBAR_INDEX: 'NAVBAR_INDEX',
};

function dataReducer(data, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_BULK_MODE: {
      localStorage.setItem(BULK_MODE, !data.bulkMode);
      return {
        ...data,
        bulkMode: !data.bulkMode,
      };
    }

    case ACTIONS.TOGGLE_GRID_SIZE: {
      if (data.gridSize === GRID_SIZE.GRID_3X3) {
        localStorage.setItem(DEFAULT_GRID_SIZE, GRID_SIZE.GRID_2X2);
        return { ...data, gridSize: GRID_SIZE.GRID_2X2 };
      }

      localStorage.setItem(DEFAULT_GRID_SIZE, GRID_SIZE.GRID_3X3);
      return { ...data, gridSize: GRID_SIZE.GRID_3X3 };
    }

    case ACTIONS.NAVBAR_INDEX: {
      return { ...data, tabIndex: action.payload };
    }

    default: {
      return data;
    }
  }
}

export default function DataProvider({ children }) {
  const [data, dispatch] = useReducer(dataReducer, initialState);

  return (
    <DataContext.Provider value={data}>
      <DataDispatchContext.Provider value={dispatch}>
        {children}
      </DataDispatchContext.Provider>
    </DataContext.Provider>
  );
}
