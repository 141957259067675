import { CgSpinner } from 'react-icons/cg';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { MESSAGE, USER_DATA } from '../../constants';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState({
    Date: new Date().toString(),
    Name: '',
    Phone: '',
    Address: '',
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (e) => {
    setData({ ...data, Phone: e });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(data);

    const regex =
      /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;

    // console.log(regex.test(data.Phone));

    if (!regex.test(data.Phone)) {
      // console.log('invalid');
      toast.error(MESSAGE.BAD_PHONE);
      return;
    }

    if (!data.Name) {
      // console.log('invalid user name');
      return;
    }

    if (!data.Address) {
      // console.log('invalid Address');
      return;
    }

    const user = {
      name: data.Name,
      phone: data.Phone,
      address: data.Address,
      dateJoined: data.Date,
    };

    localStorage.setItem(USER_DATA, JSON.stringify(user));

    toast.success(MESSAGE.SUCCESFUL_LOGIN);

    navigate('/cart');
  };

  return (
    <div className="w-80 h-4/6 flex flex-col mx-auto justify-start gap-12 rounded-lg p-4">
      <div className="max-w-xs flex justify-center">
        <div className="w-7/12">
          <img src="/wordmark.png" alt="brand logo" />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <h1 className="font-semibold text-2xl text-center">Welcome</h1>
        <form
          className="w-full flex flex-col justify-center gap-6"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="flex flex-col gap-2">
            <label htmlFor="" className="text-base text-gray-400 text-center">
              Enter your phone number
            </label>
            <PhoneInput
              country={'in'}
              value={data.Phone}
              onChange={handlePhoneChange}
            />
          </div>
          <input
            className="border border-x-0 border-t-0 border-b-gray-400 text-center text-gray-400 onboarding-input focus:outline-none focus:border-gray-600"
            value={data.Name}
            onChange={handleChange}
            name="Name"
            placeholder="YOUR NAME"
            required
          />
          <input
            className="border border-x-0 border-t-0 border-b-gray-400 text-center text-gray-400 onboarding-input focus:outline-none focus:border-gray-600"
            value={data.Address}
            onChange={handleChange}
            name="Address"
            placeholder="YOUR ADDRESS"
            required
          />
          <div className="flex flex-col justify-center items-center">
            <p className="text-gray-400 text-xs">
              By proceeding you agree to our
            </p>
            <a
              target="_blank"
              href="https://docs.google.com/document/d/1a2uxEzMN6qePpl8hCnxC3W0O4dmrlomENLjZZLLTTmo/edit"
              rel="noopener noreferrer"
            >
              <span className="text-[#d67c79] text-xs">Privacy Policy</span>
            </a>
          </div>
          <button
            type="submit"
            className="bg-[#d67c79] w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            <span>CONTINUE</span>
          </button>
        </form>
      </div>
    </div>
  );
}
