import { useContext, useEffect } from 'react';
import OrderListItem from './OrderListItem';
import { Spacer } from '../spacer';
import Wordmark from '../WordMark';
import { ACTIONS, DataDispatchContext } from '../../contexts/dataContext';
import { ORDERS_HISTORY } from '../../constants';

export default function OrdersPage() {
  const dispatch = useContext(DataDispatchContext);
  const orders = JSON.parse(localStorage.getItem(ORDERS_HISTORY));

  useEffect(() => {
    dispatch({ type: ACTIONS.NAVBAR_INDEX, payload: 2 });
  }, []);

  return (
    <div className="px-4 overflow-scroll no-scrollbar">
      <div className="sticky top-0 bg-white py-4">
        <Wordmark />
        <Spacer y={4} />
        <h1 className="font-semibold text-xl">Order History</h1>
        <Spacer y={2} />
        <hr className="border-1 border-gray-500 mb-2" />
      </div>
      {orders === null || Object.keys(orders).length === 0 ? (
        <div className="flex flex-col justify-center text-center h-32">
          <h2 className="font-bold">Your order history is empty.</h2>
          <p className="text-sm">
            Your past orders will show here after you place an order.
          </p>
        </div>
      ) : (
        <div className="mt-4 flex flex-col gap-3 mx-auto  overflow-scroll">
          {Object.keys(orders).map((item) => (
            <OrderListItem order={orders[item]} key={item} id={item} />
          ))}
        </div>
      )}
    </div>
  );
}
