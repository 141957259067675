import { toast } from 'react-hot-toast';
import { addItem, isEmptyField } from '../../utils/group';
import { DATA_FIELDS, OFFERS_ACTIVE } from '../../constants';
import ButtonPrimary from './buttonPrimary';
import { useContext } from 'react';
import { DataContext } from '../../contexts/dataContext';

export default function ButtonAddProduct({
  orderKey,
  order,
  setOrders,
  variant,
}) {
  let { bulkMode } = useContext(DataContext);

  const offersActive = JSON.parse(localStorage.getItem(OFFERS_ACTIVE));

  if (offersActive) {
    bulkMode = true;
  }

  if (
    Object.keys(order).length !== 0 &&
    ((bulkMode && isEmptyField(order[DATA_FIELDS.LARGE_QUANTITY])) ||
      (!bulkMode && isEmptyField(order[DATA_FIELDS.SMALL_QUANTITY])))
  ) {
    return (
      <ButtonPrimary
        handleClick={() => {
          toast.success('Item not available in selected quantity');
        }}
        disabled={true}
        variant={variant}
      >
        Add
      </ButtonPrimary>
    );
  }

  return (
    <ButtonPrimary
      handleClick={() => {
        const updatedOrders = addItem(orderKey);
        setOrders(updatedOrders);
      }}
      // style={{ width: 92.3 }}
      variant={variant}
      disabled={false}
    >
      Add
    </ButtonPrimary>
  );
}
