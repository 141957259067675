export const ICON_SIZE = {
  SMALL: 24,
  MEDIUM: 30,
  LARGE: 64,
};

export const APP_DATA = 'data';
export const ORDERS_DATA = 'orders';
export const SUBCATEGORIES_DATA = 'subcategories';
export const OFFERS_DATA = 'offers';
export const CATEGORIES_DATA = 'categories';
export const DEFAULT_GRID_SIZE = 'default_grid_size';
export const LAST_DELETED_KEY = 'last_deleted_key';
export const SCROLL_TOP = 'scroll_top';
export const SORT_OPTION = 'sort_option';
export const OFFERS_ACTIVE = 'offers_active';
export const ORDERS_HISTORY = 'orders_history';
export const SEARCH_SUBCATEGORY = 'search_subcategory';
export const USER_DATA = 'user_data';

export const DATA_FIELDS = {
  PRODUCT_NAME: 'Name',
  CATEGORY: 'Category',
  SUBCATEGORY: 'Sub Category',
  PRICE: 'MRP',
  QUANTITY: 'Unit',
  VARIANT: 'Variant',
  SMALL_QUANTITY: 'Small Quantity',
  LARGE_QUANTITY: 'Large Quantity',
  IMG: 'Image URL',
  CARTON_SIZE: 'Carton Size',
  DESCRIPTION: 'Description',
  ALIAS: 'Alias',
};

export const SORT_OPTIONS = {
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
  RELEVEANCE: 'relevance',
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
};

export const SORT_OPTIONS_MESSAGE = {
  name_asc: 'Name (A - Z)',
  name_desc: 'Name (Z - A)',
  relevance: 'Relevance',
};

// export const USER_DATA = {
//   Name: 'name',
//   Phone: 'phone',
//   Address: 'address',
// };

export const BULK_MODE = 'bulk_mode';

export const COLORS = {
  DARK_GREEN: '#386B26',
  TEXT_4: '#C9CDD2',
  SEARCH_BAR: '#949BA5',
};

export const MESSAGE = {
  EMPTY_ORDER: 'Cart Empty. Please add items to cart to place an order.',
  SUCCESSFUL_ORDER: 'Order placed Successfully.',
  ERROR_ORDER:
    'Something went wrong while placing an order please try again later.',
  BAD_PHONE: 'Please provide a valid phone number.',
  SUCCESFUL_LOGIN: 'Logged in successfully.',
};

export const ORDER_RESPONSE = {
  SUCCESS: 'success',
};

export const GRID_SIZE = {
  GRID_3X3: 3,
  GRID_2X2: 2,
};

export const PLACEHOLDER_IMAGE =
  'https://ik.imagekit.io/5ehplay1a2/DT/wordmark.png?updatedAt=1688485364831';
