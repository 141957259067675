import { SearchResults } from '../search/searchResults';
import { useContext, useEffect, useState } from 'react';
import Papa from 'papaparse';
import {
  GRID_SIZE,
  ICON_SIZE,
  OFFERS_ACTIVE,
  OFFERS_DATA,
} from '../../constants';
import { CgSpinner } from 'react-icons/cg';
import { Spacer } from '../spacer';
import Wordmark from '../WordMark';
import { ACTIONS, DataDispatchContext } from '../../contexts/dataContext';

export default function Offers({ setSelected }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useContext(DataDispatchContext);

  useEffect(() => {
    dispatch({ type: ACTIONS.NAVBAR_INDEX, payload: 1 });
    let localProducts;

    localStorage.setItem(OFFERS_ACTIVE, true);
    if (localStorage.getItem(OFFERS_DATA) !== null) {
      // console.log(localStorage.getItem(OFFERS_DATA));
      localProducts = JSON.parse(localStorage.getItem(OFFERS_DATA));
    }

    if (localProducts) {
      setProducts(localProducts);
      setLoading(false);
    }

    const getData = async () => {
      Papa.parse(process.env.REACT_APP_OFFER_URL, {
        header: true,
        download: true,
        complete: (results) => {
          // const groupedData = group(results.data, DATA_FIELDS.VARIANT);
          localStorage.setItem(OFFERS_DATA, JSON.stringify(results.data));
          setProducts(results.data);
          setLoading(false);
        },
        error: (e) => {
          console.log(e);
        },
      });
    };

    getData();
  }, [JSON.stringify(products)]);

  return (
    <div className="h-full overflow-scroll">
      <Spacer y={4} />
      <Wordmark />
      <Spacer y={4} />

      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <CgSpinner size={ICON_SIZE.LARGE} className="mt-1 animate-spin" />
        </div>
      ) : (
        <div className="overflow-y-scroll">
          <SearchResults
            products={products}
            bulkMode={true}
            gridSize={GRID_SIZE.GRID_2X2}
            showProductCount={false}
            offersEnabled={true}
          />
        </div>
      )}
    </div>
  );
}
