import { useContext } from 'react';
import { ICON_SIZE } from '../constants';
import { Link } from 'react-router-dom';
import {
  ACTIONS,
  DataContext,
  DataDispatchContext,
} from '../contexts/dataContext';

const navbarItems = [
  {
    title: 'Home',
    iconSelected: (
      <img
        src="/HomeFilled.png"
        alt="home select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    icon: (
      <img
        src="/HomeOutline.png"
        alt="home select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    route: '/',
  },
  {
    title: 'Offers',
    iconSelected: (
      <img
        src="/DiscountFilled.png"
        alt="home select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    icon: (
      <img
        src="/DiscountOutline.png"
        alt="home select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    route: '/offers',
  },
  {
    title: 'Order History',
    iconSelected: (
      <img
        src="/OrderHistoryFilled.png"
        alt="Order history select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    icon: (
      <img
        src="/OrderHistoryOutline.png"
        alt="Order history select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    route: '/orders',
  },
  {
    title: 'Basket',
    iconSelected: (
      <img
        src="/CartFilled.png"
        alt="home select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    icon: (
      <img
        src="/CartOutline.png"
        alt="home select"
        height={ICON_SIZE.SMALL}
        width={ICON_SIZE.SMALL}
      />
    ),
    route: '/cart',
  },
];

export default function Navbar() {
  const { tabIndex } = useContext(DataContext);
  const dispatch = useContext(DataDispatchContext);

  return (
    <nav className="drop-shadow-2xl w-full h-14 py-3 bg-white flex flex-row justify-around items-center z-10">
      {navbarItems.map((item, index) => {
        return (
          <Link
            className="w-24"
            to={item.route}
            key={index}
            onClick={() =>
              dispatch({ type: ACTIONS.NAVBAR_INDEX, payload: index })
            }
          >
            <div className="pt-w flex flex-col items-center">
              <span>{tabIndex === index ? item.iconSelected : item.icon}</span>
              <span style={{ marginTop: 1 }} className="text-xs">
                {item.title}
              </span>
            </div>
          </Link>
        );
      })}
    </nav>
  );
}
