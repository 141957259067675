import { Link } from 'react-router-dom';
import { DATA_FIELDS } from '../../constants';

export function ExploreItem({ item }) {
  const img = item[DATA_FIELDS.IMG];
  const name = item[DATA_FIELDS.PRODUCT_NAME];
  const alt = item[DATA_FIELDS.DESCRIPTION];

  return (
    <div className="flex flex-col items-center gap-2">
      <Link
        to="/search"
        state={{
          category: name,
          img,
          alt,
        }}
      >
        <div className="rounded-lg max-w-20 max-h-20 aspect-square bg-slate-400 relative">
          <img
            className="w-full h-full rounded-lg object-cover"
            src={img}
            alt={alt}
          />
        </div>
      </Link>
      <span className="text-center text-gray-900 text-[14px] font-semibold leading-tight tracking-wide break-keep">
        {name}
      </span>
    </div>
  );
}
