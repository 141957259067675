import { MdKeyboardBackspace, MdSearch } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { GRID_SIZE, ICON_SIZE, OFFERS_ACTIVE } from '../../constants';
import { BiGridHorizontal, BiGridSmall } from 'react-icons/bi';
import ToggleSwitch from './toggleSwitch';
import './toggle.css';
import { useContext } from 'react';
import {
  ACTIONS,
  DataContext,
  DataDispatchContext,
} from '../../contexts/dataContext';

export default function NavigationPanel({
  showGrid = true,
  showSearch = true,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const offersActive = JSON.parse(localStorage.getItem(OFFERS_ACTIVE));

  const { gridSize } = useContext(DataContext);
  const dispatch = useContext(DataDispatchContext);

  if (location.state) {
    const { category, img, alt } = location.state;
    return (
      <div className="min-h-[48px] flex flex-row justify-between items-center pr-2">
        <div className="flex flex-row">
          <div className="w-6 flex flex-row items-center">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <MdKeyboardBackspace
                size={ICON_SIZE.SMALL}
                className="fill-black"
              />
            </button>
          </div>
          {category && (
            <div className="ml-2 flex flex-row items-center">
              <div className="flex flex-row justify-between rounded-lg w-9 h-9 bg-slate-400">
                <img
                  className="object-cover rounded-lg"
                  width={80}
                  height={80}
                  src={img}
                  alt={alt}
                />
              </div>
              <div className="flex flex-col ml-2">
                <p className="font-bold text-sm">All Products</p>
                <p className="text-sm">in {category}</p>
              </div>
            </div>
          )}
        </div>
        <div className="min-w-16 h-full flex gap-8 items-center">
          {showGrid && (
            <button
              onClick={() => {
                dispatch({ type: ACTIONS.TOGGLE_GRID_SIZE });
              }}
            >
              <div className="rounded-full border-gray-600 border p-1">
                {gridSize === GRID_SIZE.GRID_3X3 ? (
                  <BiGridHorizontal size={ICON_SIZE.SMALL} />
                ) : (
                  <BiGridSmall size={ICON_SIZE.SMALL} />
                )}
              </div>
            </button>
          )}

          {!offersActive && <ToggleSwitch />}

          {showSearch && (
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <MdSearch size={ICON_SIZE.SMALL} />
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="pt-1 flex flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <MdKeyboardBackspace size={ICON_SIZE.SMALL} className="fill-black" />
        </button>
      </div>
    </div>
  );
}
