export default function OrderSucess() {
  return (
    <div className="flex flex-col h-full w-full gap-2 justify-center items-center">
      <video className="w-64" autoPlay loop muted>
        <source src="/sucess.mp4" type="video/mp4" />
      </video>
      <h1 className="font-semibold text-xl">Order placed sucessfully</h1>
    </div>
  );
}
