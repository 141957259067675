import { extractNumber } from '../utils/group';

export default function Price({ price }) {
  if (!price || price === '') {
    return <> </>;
  }

  if (price && price.search('/') !== -1) {
    const prices = price.split('/');
    return (
      <>
        <span className="line-through font-normal gray-700">
          {`₹ ${extractNumber(prices[0])}`}
        </span>
        <span className="ml-3">{`₹ ${extractNumber(prices[1])}`}</span>
        {price && '/-'}
      </>
    );
  }

  return <>{`₹ ${extractNumber(price)}`}</>;
}
