import { useContext } from 'react';
import { BULK_MODE } from '../../constants';
import BoxClosed from '../BoxClosed';
import {
  ACTIONS,
  DataContext,
  DataDispatchContext,
} from '../../contexts/dataContext';

export default function ToggleSwitch() {
  const { bulkMode } = useContext(DataContext);
  const dispatch = useContext(DataDispatchContext);

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={bulkMode}
        onChange={() => {
          dispatch({ type: ACTIONS.TOGGLE_BULK_MODE });
          localStorage.setItem(BULK_MODE, String(!bulkMode));
        }}
      />
      <div className="slider">
        <div className="circle">
          <BoxClosed />

          <svg
            className="pause"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3038 6.35276L3.06299 3.17627V14.7272L10.3038 17.9999V6.35276Z"
              fill="#E6A352"
            />
            <path
              d="M10.3033 6.35321L3.0625 3.17671L7.51836 1.6366L14.7591 4.90935L10.3033 6.35321Z"
              fill="#AC752E"
            />
            <path
              d="M14.7593 4.90913L7.51855 1.63638L12.6242 0L20.0507 3.17649L14.7593 4.90913Z"
              fill="#AC752E"
            />
            <path
              d="M12.8097 8.27813L12.624 0L20.0505 3.17649V10.7808L12.8097 8.27813Z"
              fill="#BC8239"
            />
            <path
              d="M20.0509 3.17627L10.3037 6.35276V17.9999L20.0509 14.7272V3.17627Z"
              fill="#D59240"
            />
            <path
              d="M17.1727 1.25135L12.624 0L20.0505 3.17649L21.9999 3.46526L17.1727 1.25135Z"
              fill="#E8B06D"
            />
            <path
              d="M7.89017 12.8988L3.06299 8.85596L10.3038 6.35327L7.89017 12.8988Z"
              fill="#B9874A"
            />
            <path
              d="M0 7.31534L3.0634 3.17627L10.3042 6.35276L7.61209 11.2619L0 7.31534Z"
              fill="#EDC28E"
            />
            <path
              d="M12.6248 0L3.06327 3.17649L1.021 3.56152L10.3969 0.481287L12.6248 0Z"
              fill="#EEAA56"
            />
            <path
              d="M12.9958 13.3801L10.3037 6.35327L20.5151 8.56719L12.9958 13.3801Z"
              fill="#B9874A"
            />
            <path
              d="M13.5528 11.6469L10.3037 6.35276L20.0509 3.17627L21.5362 8.18165L13.5528 11.6469Z"
              fill="#EDB46F"
            />
          </svg>
        </div>
      </div>
    </label>
  );
}
