import { MdImportExport } from 'react-icons/md';
import { SORT_OPTIONS_MESSAGE } from '../../constants';

export function SearchOptions({ sortOption, handleSortOpen }) {
  // console.log(sortOption, SORT_OPTIONS_MESSAGE[sortOption], SORT_OPTIONS_MESSAGE['price_asc'])
  return (
    <div>
      <button
        className="border px-2 py-2 border-black rounded-xl"
        onClick={handleSortOpen}
      >
        <div className="flex flex-row items-center">
          <MdImportExport className="fill-black" />
          <p className="ml-1 font-semibold text-sm">
            Sort: {SORT_OPTIONS_MESSAGE[sortOption]}
          </p>
        </div>
      </button>
    </div>
  );
}
