import React, { useContext, useEffect, useState } from 'react';
import { ExploreItem } from './exploreItem';
import {
  group,
  parseFiles,
  resetScroll,
  resetSubcategory,
  subcategoriesByCategory,
} from '../../utils/group';
import {
  APP_DATA,
  CATEGORIES_DATA,
  DATA_FIELDS,
  ICON_SIZE,
  OFFERS_ACTIVE,
  SUBCATEGORIES_DATA,
} from '../../constants';
import Wordmark from '../WordMark';
import { Spacer } from '../spacer';
import { SearchBar } from '../search/searchBar';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import { CgSpinner } from 'react-icons/cg';
import { ACTIONS, DataDispatchContext } from '../../contexts/dataContext';

function Explore() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useContext(DataDispatchContext);

  useEffect(() => {
    dispatch({ type: ACTIONS.NAVBAR_INDEX, payload: 0 });
    resetScroll();
    resetSubcategory();
    localStorage.setItem(OFFERS_ACTIVE, false);
    if (localStorage.getItem(CATEGORIES_DATA) !== null) {
      setCategories(JSON.parse(localStorage.getItem(CATEGORIES_DATA)));
      setLoading(false);
    }

    const getData = async () => {
      const localData = await parseFiles(
        process.env.REACT_APP_SHEET_DB_URL.split(',')
      );

      // console.log(localData);
      await Papa.parse(process.env.REACT_APP_CATEGORY_URL, {
        header: true,
        download: true,
        complete: (results) => {
          // const groupedData = group(results.data, DATA_FIELDS.VARIANT);
          localStorage.setItem(CATEGORIES_DATA, JSON.stringify(results.data));
          setCategories(results.data);
        },
        error: (e) => {
          console.log(e);
        },
      });

      // console.log(results);
      const groupedData = group(localData, DATA_FIELDS.VARIANT);
      const subcategories = subcategoriesByCategory(localData);
      localStorage.setItem(SUBCATEGORIES_DATA, JSON.stringify(subcategories));
      localStorage.setItem(APP_DATA, JSON.stringify(groupedData));
      setLoading(false);
    };

    getData();
  }, [JSON.stringify(categories)]);

  return (
    <div className="grow flex flex-col overflow-scroll no-scrollbar">
      <Spacer y={4} />
      <Wordmark />
      <Spacer y={4} />
      <Link to="/search" className="mx-4">
        <SearchBar />
      </Link>
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <CgSpinner size={ICON_SIZE.LARGE} className="mt-1 animate-spin" />
        </div>
      ) : (
        <div className="grow mt-6 overflow-scroll mx-4 no-scrollbar">
          <div className="grow grid grid-cols-3 gap-x-10 gap-y-6 overflow-scroll no-scrollbar">
            {categories.map((item, index) => {
              return <ExploreItem key={index} item={item} />;
            })}
          </div>
        </div>
      )}
      <Link
        className="w-fit mx-auto"
        to="https://www.youtube.com/@DineshTrading/videos"
        target="_blank"
      >
        <div className="flex flex-row rounded-2xl justify-center gap-3 items-center border border-gray-400 py-2 px-4">
          <img src="/Youtube.png" alt="youtube icon" className="h-6" />
          <p className="text-sm font-semibold">Watch the Product Demo</p>
        </div>
      </Link>
      <Spacer y={4} />
    </div>
  );
}

export default Explore;
