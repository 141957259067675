import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { COLORS, ICON_SIZE } from '../constants';

export default function Counter({ value, handleAdd, handleSubtract }) {
  return (
    <div className="flex flex-row items-center justify-center h-8">
      <button className="" onClick={handleSubtract}>
        <MdRemoveCircleOutline
          color={COLORS.DARK_GREEN}
          size={ICON_SIZE.MEDIUM}
        />
      </button>
      <p className="text-sm w-16 mx-1 text-center">{value}</p>
      <button onClick={handleAdd}>
        <MdAddCircleOutline color={COLORS.DARK_GREEN} size={ICON_SIZE.MEDIUM} />
      </button>
    </div>
  );
}
