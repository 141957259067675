import { DATA_FIELDS } from '../../constants';
import { MdArrowDropDown } from 'react-icons/md';

export default function QuantitySelect({
  handleOpen,
  firstItem,
  textStyle,
  variants,
}) {
  return variants ? (
    <div
      className="mt-1 border border-gray-400 flex flex-row justify-between items-center p-2 h-8 overflow-hidden"
      onClick={variants?.length === 1 ? () => {} : handleOpen}
    >
      <p className={textStyle}>
        {firstItem[DATA_FIELDS.QUANTITY]?.trim() === ''
          ? 'Variants'
          : firstItem[DATA_FIELDS.QUANTITY].trim()}
      </p>

      {variants?.length === 1 ? <></> : <MdArrowDropDown />}
    </div>
  ) : (
    <></>
  );
}
