import { MdSearch } from 'react-icons/md';
import { ICON_SIZE } from '../../constants';

export function SearchBar({ searchQuery, setSearchQuery }) {
  return (
    <div className="relative">
      <input
        className={`w-full border-gray-400 border rounded-full h-10 pl-12 text-sm`}
        placeholder="Find Products"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />
      <MdSearch
        size={ICON_SIZE.SMALL}
        className="absolute top-1/2 -translate-y-1/2 left-3"
      />
    </div>
  );
}
