import { useEffect, useRef, useState } from 'react';
import NavigationPanel from './navigationPanel';
import { Spacer } from '../spacer';
import { SearchBar } from './searchBar';
import { SearchOptions } from './searchOptions';
import { Subcategories } from './subcategories';
import { SearchResults } from './searchResults';
import {
  APP_DATA,
  SCROLL_TOP,
  SEARCH_SUBCATEGORY,
  SORT_OPTION,
  SORT_OPTIONS,
} from '../../constants';
import {
  groupByCategory,
  groupBySubcategory,
  searchProducts,
  sortByName,
} from '../../utils/group';
import { useLocation } from 'react-router-dom';
import SortModal from '../modals/sortModal';
import Divider from '../divider';

export default function Search() {
  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState(SORT_OPTIONS.NAME_ASC);

  const handleSortOpen = () => setSortOpen(true);
  const handleSortClose = () => setSortOpen(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('all');
  const [scrollTop, setScrollTop] = useState(0);

  const location = useLocation();
  const parentDiv = useRef(null);

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem(APP_DATA));
    let localCategory = '';
    let localSortOption = localStorage.getItem(SORT_OPTION);
    let localSubcategory = localStorage.getItem(SEARCH_SUBCATEGORY);

    if (localSortOption !== null) {
      setSortOption(localSortOption);
    }

    if (localSubcategory !== null) {
      setSubcategory(localSubcategory);
    }

    const initalScroll = localStorage.getItem(SCROLL_TOP);

    if (initalScroll !== 0) {
      parentDiv.current.scrollTop = initalScroll;
    }

    if (location.state?.category) {
      localCategory = location.state.category;
      // console.log(localCategory);
    }

    if (localCategory !== '' && searchQuery === '') {
      localData = groupByCategory(localData, localCategory);
    }

    if (!(subcategory === '' || subcategory.toLowerCase() === 'all')) {
      localData = groupBySubcategory(localData, subcategory);
      // console.log('subcategory filter', localData);
    }

    if (searchQuery !== '') {
      localData = searchProducts(localData, searchQuery.trim());
      // console.log('search query', searchQuery, res);
    }

    if (localSortOption === null) {
      localSortOption = sortOption;
    }

    localData = sortByName(localData, localSortOption);
    // console.log('sorted', sortByPrice(localData, sortOption));

    if (localSortOption !== null) {
      setSortOption(localSortOption);
    }

    setProducts(localData);
    setCategory(localCategory);
  }, [
    category,
    subcategory,
    sortOption,
    searchQuery,
    location.state?.category,
  ]);

  const handleScroll = (e) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  return (
    <div
      ref={parentDiv}
      className="h-full flex flex-col grow overflow-scroll no-scrollbar"
      onScroll={(e) => {
        handleScroll(e);
      }}
    >
      <div className="bg-white w-full p-2 sticky top-0">
        <NavigationPanel showSearch={false} />
        <Spacer y={3} />
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Spacer y={1} />
      </div>
      <Divider />
      <div className="bg-white w-full p-2">
        <SearchOptions
          sortOption={sortOption}
          handleSortOpen={handleSortOpen}
        />
        <Subcategories
          category={category}
          subcategory={subcategory}
          setSubcategory={setSubcategory}
        />
      </div>

      <SearchResults products={products} scrollTop={scrollTop} />
      <SortModal
        sortOpen={sortOpen}
        handleSortClose={handleSortClose}
        setSortOption={setSortOption}
        sortOption={sortOption}
      />
    </div>
  );
}
