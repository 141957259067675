import { useContext, useState } from 'react';
import { SearchItem } from './searchItem';
import { Spacer } from '../spacer';
import Divider from '../divider';
import { GRID_SIZE, OFFERS_ACTIVE } from '../../constants';
import { DataContext } from '../../contexts/dataContext';

export function SearchResults({
  products,
  showProductCount = true,
  scrollTop,
}) {
  const [modalIndex, setModalIndex] = useState(0);
  let { gridSize } = useContext(DataContext);
  const offersActive = JSON.parse(localStorage.getItem(OFFERS_ACTIVE));

  if (offersActive) {
    gridSize = GRID_SIZE.GRID_2X2;
  }

  const gridStyle = `mt-6 grid ${
    gridSize === GRID_SIZE.GRID_3X3 ? 'grid-cols-3' : 'grid-cols-2'
  } lg:grid-cols-5 gap-3 mx-2`;

  return (
    <div className="w-full bg-[#EBEDF0]">
      {showProductCount && (
        <div className="bg-white">
          <h2 className=" text-gray-400 px-2 mb-1 font-semibold text-sm">
            {products.length} products found
          </h2>
          <div className="pb-1.5" />
        </div>
      )}
      <div className={gridStyle}>
        {products.length > 0 ? (
          products.map((item, index) => {
            return (
              <SearchItem
                key={index}
                product={item}
                index={index}
                modalIndex={modalIndex}
                setModalIndex={setModalIndex}
                scrollTop={scrollTop}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
      <Spacer y={4} />
    </div>
  );
}
