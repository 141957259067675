import {
  APP_DATA,
  DATA_FIELDS,
  ORDERS_DATA,
  SCROLL_TOP,
  SEARCH_SUBCATEGORY,
  SORT_OPTIONS,
} from '../constants';
import Papa from 'papaparse';

export function group(list, key) {
  const arr = list.reduce((prev, curr) => {
    (prev[curr[key]] = prev[curr[key]] || []).push(curr);
    return prev;
  }, {});

  const keys = Object.keys(arr);

  keys.forEach((item) => {
    let sortedList = arr[item];
    sortedList = sortedList.sort((a, b) => {
      let priceA = extractNumber(a[DATA_FIELDS.PRICE]);
      let priceB = extractNumber(b[DATA_FIELDS.PRICE]);

      if (priceA < priceB) {
        return -1;
      }

      if (priceA > priceB) {
        return 1;
      }

      return 0;
    });

    arr[item] = sortedList;
  });

  const data = keys.map((item) => {
    return {
      name: arr[item][0][DATA_FIELDS.PRODUCT_NAME],
      variants: arr[item],
      category: arr[item][0][DATA_FIELDS.CATEGORY],
      subcategory: arr[item][0][DATA_FIELDS.SUBCATEGORY],
    };
  });

  return data;
}

export function groupByCategory(data, category) {
  if (!data) {
    return;
  }

  const filteredData = data.filter((item) => {
    let categories = item.category?.split(',');

    if (categories) {
      for (let c of categories) {
        if (c.trim().toLowerCase() === category.trim().toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  });

  return filteredData;
}

export function groupBySubcategory(data, subcategory) {
  // console.log('data', data);
  if (!data) {
    console.log('error: bad data');
    return;
  }
  const filteredData = data.filter(
    (item) => item.subcategory.toLowerCase() === subcategory.toLowerCase()
  );

  return filteredData;
}

export function extractNumber(s) {
  if (!s) {
    return 0;
  }

  return Number(s.replace(/[^0-9\.]+/g, ''));
}

export function sortByName(data, order) {
  if (!data) {
    return [];
  }

  if (order === SORT_OPTIONS.RELEVEANCE) {
    return data;
  }

  let localData = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  if (order === SORT_OPTIONS.NAME_ASC) {
    // console.log(localData);
    return localData;
  }

  localData = localData.reverse();
  return localData;
}

export function findProductByID(id) {
  const products = JSON.parse(localStorage.getItem(APP_DATA));
  // console.log('data', products);
  if (!products || products.length === 0) {
    return {};
  }

  const product = products.find((item) => item.name === id);
  // console.log('found', product);
  return product;
}

export function ungroupOrderType(orders) {
  let processedOrders = [];
  // console.log(orders);
  Object.keys(orders).forEach((item) => {
    let variants = orders[item].split('+');

    variants.forEach((variant) => {
      processedOrders.push({
        name: item,
        quantity: variant.trim(),
      });
    });
  });

  return processedOrders;
}

// export function sortByPrice(data, order) {
//   if (!data) {
//     return [];
//   }

//   if (order === SORT_OPTIONS.RELEVEANCE.CODE) {
//     return data;
//   }

//   data.sort(
//     (a, b) =>
//       extractNumber(a.variants[0][DATA_FIELDS.PRICE]) -
//       extractNumber(b.variants[0][DATA_FIELDS.PRICE])
//   );

//   if (order === SORT_OPTIONS.PRICE_DESC) {
//     data.reverse();
//   }

//   return data;
// }

export function isEmptyField(value) {
  return value?.trim() === '-' || value?.trim() === '';
}

export function createKey(item, bulkMode) {
  const name = item[DATA_FIELDS.PRODUCT_NAME];
  const unit = item[DATA_FIELDS.QUANTITY];
  const price = item[DATA_FIELDS.PRICE];
  const quantity = bulkMode
    ? !isEmptyField(item[DATA_FIELDS.LARGE_QUANTITY])
      ? item[DATA_FIELDS.LARGE_QUANTITY]
      : 'NA'
    : !isEmptyField(item[DATA_FIELDS.SMALL_QUANTITY])
    ? item[DATA_FIELDS.SMALL_QUANTITY]
    : 'NA';
  const bulkModeAdjusted =
    (bulkMode && !isEmptyField(item[DATA_FIELDS.LARGE_QUANTITY])) ||
    (!bulkMode && !isEmptyField(item[DATA_FIELDS.SMALL_QUANTITY]));

  const image = item[DATA_FIELDS.IMG];

  return `${name}#${unit}#${price}#${quantity}#${bulkModeAdjusted}#${image}`;
}

export function subtractItem(key, setOrders) {
  if (localStorage.getItem(ORDERS_DATA) === null) {
    return;
  }

  let order = JSON.parse(localStorage.getItem(ORDERS_DATA));

  if (!(key in order)) {
    return;
  }
  let lastItem = false;

  // console.log(order, key);
  if (order[key] === 1) {
    delete order[key];
    lastItem = true;
  }

  if (key in order) {
    order[key] = order[key] - 1;
  }

  if (setOrders) {
    setOrders(order);
  }

  localStorage.setItem(ORDERS_DATA, JSON.stringify(order));
  return { lastItem, key };
}

export function addItem(key, setOrders) {
  let order = {};

  if (localStorage.getItem(ORDERS_DATA) !== null) {
    order = JSON.parse(localStorage.getItem(ORDERS_DATA));
    if (key in order) {
      order[key] = order[key] + 1;
    } else {
      order[key] = 1;
    }
  } else {
    order = {
      [`${key}`]: 1,
    };
  }

  localStorage.setItem(ORDERS_DATA, JSON.stringify(order));
  if (setOrders) {
    setOrders(order);
  }
  return order;
}

export function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function subcategoriesByCategory(data) {
  let res = {};

  if (!data) {
    return;
  }

  data.forEach((item, index) => {
    if (item[DATA_FIELDS.CATEGORY] in res) {
      res[item[DATA_FIELDS.CATEGORY]].add(item[DATA_FIELDS.SUBCATEGORY]);
    } else {
      res[item[DATA_FIELDS.CATEGORY]] = new Set();
    }
  });

  Object.keys(res).forEach((item) => {
    res[item] = Array.from(res[item]);
  });

  return res;
}

export function parseOrderKey(key) {
  const values = key.split('#');
  // console.log('parsed key', values)
  return {
    name: values[0],
    unit: values[1],
    price: values[2],
    quantity: values[3],
    bulkMode: values[4],
    image: values[5],
  };
}

export function processOrder(orders) {
  let processedOrder = {};

  if (!orders || orders.length === 0) {
    return [];
  }

  Object.keys(orders).forEach((key) => {
    let parsedKey = parseOrderKey(key);
    let shortKey = `${parsedKey.name} ${parsedKey.unit}`;
    if (processedOrder[shortKey] === undefined) {
      processedOrder[shortKey] = `${orders[key]} ${parsedKey.quantity}`;
    } else {
      processedOrder[shortKey] += ` + ${orders[key]} ${parsedKey.quantity}`;
    }
  });

  return processedOrder;
}

export function parseAlias(s) {
  if (!s) {
    return [];
  }

  let aliases = [];
  s = s.split(',');

  for (let alias of s) {
    if (alias.trim().length > 0) {
      aliases.push(alias.trim());
    }
  }
  if (aliases.length > 0) {
    console.log(s, aliases);
  }
  return aliases;
}

export function searchProducts(data, query) {
  query = query.replace('\\', '\\\\');
  let regex = new RegExp(query, 'i');

  let filteredData = data.filter((item) => {
    let alias = new Set();

    for (let variant of item.variants) {
      const variantAlias = parseAlias(variant[DATA_FIELDS.ALIAS]);
      variantAlias.forEach((item) => alias.add(item));
    }

    for (let key of alias) {
      if (regex.test(key)) {
        return true;
      }
    }

    return regex.test(item.name);
  });

  return filteredData;
}

export function calculateTotal(orders) {
  let total = 0;

  if (!orders || orders.length === 0) {
    return 0;
  }

  Object.keys(orders).forEach((item) => {
    // console.log('parsed key item', item)

    let parsedKey = parseOrderKey(item);
    let cartonSize = Number(extractNumber(parsedKey.cartonSize)) || 1;
    let price = Number(extractNumber(parsedKey.price));

    // console.log(
    //   'parsed key',
    //   parsedKey.cartonSize,
    //   parsedKey.price,
    //   cartonSize,
    //   price,
    //   parsedKey.name,
    //   parsedKey.bulkMode,
    //   Number(orders[item])
    // );

    // console.log(cartonSize)
    if (parsedKey.bulkMode === 'true') {
      // console.log('parsed key', 'price', price)
      total += price * cartonSize * Number(orders[item]);
    } else {
      // console.log('parsed key', 'price', price)
      total += price * Number(orders[item]);
    }
    // console.log('parsed key', total);
  });
  // console.log(total);
  return total;
}

export async function parseFiles(files) {
  const filesData = [];
  // console.log(files);
  await Promise.all(
    [...files].map(
      (file) =>
        new Promise((resolve, reject) =>
          Papa.parse(file, {
            header: true,
            download: true,
            complete: resolve, // Resolve each promise
            error: reject,
          })
        )
    )
  )
    .then((results) => {
      results.forEach((result, index) => {
        filesData.push(...result.data);
      });
    })
    .catch((err) => console.log('Something went wrong:', err));

  return filesData;
}

export function resetScroll() {
  localStorage.setItem(SCROLL_TOP, 0);
}

export function resetSubcategory() {
  localStorage.setItem(SEARCH_SUBCATEGORY, 'All');
}
