import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { ICON_SIZE, SORT_OPTION, SORT_OPTIONS } from '../../constants';

const options = [
  { name: 'Name (A - Z)', value: SORT_OPTIONS.NAME_ASC },
  { name: 'Name (Z - A)', value: SORT_OPTIONS.NAME_DESC },
  { name: 'Relevance', value: SORT_OPTIONS.RELEVEANCE },
];

export default function SortModal({
  sortOpen,
  handleSortClose,
  sortOption,
  setSortOption,
}) {
  // console.log('sort open state', sortOpen);
  const [value, setValue] = useState(sortOption);

  if (!sortOpen) {
    return <></>;
  }

  return (
    <div>
      <div onClick={() => handleSortClose()} className="backdrop z-0" />
      <div
        style={{ translate: '0rem -3.5rem' }}
        className="bg-white fixed bottom-0 left-0  w-full drop-shadow-2xl rounded-t-2xl ios-safe-bottom"
      >
        <div>
          <h2 className="py-6 pl-3 font-bold">Sort</h2>
          <div style={{ width: '90%' }} className="mx-auto mt-1">
            {options.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    onClick={(e) => setValue(item.value)}
                    className="flex flex-row justify-between mt-3 mb-2"
                  >
                    <span>{item.name}</span>
                    <input
                      className="scale-150 accent-green-600"
                      type="radio"
                      name="sort"
                      onChange={(e) => setValue(e.target.value)}
                      value={item.value}
                      checked={value === item.value}
                    />
                  </div>
                  {item.value !== options[options.length - 1].value ? (
                    <hr className="border-1 mt-1 border-gray-400 mb-2" />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
          <div className="mt-3 py-3 flex justify-center w-4/5 mx-auto">
            <button
              onClick={() => {
                setValue(sortOption);
              }}
              variant="outlined"
              className="rounded-full border border-red-500 w-full py-2"
            >
              <span className="px-3 text-red-500 font-semibold">Reset</span>
            </button>
            <button
              onClick={() => {
                setSortOption(value);
                localStorage.setItem(SORT_OPTION, value);
                handleSortClose();
              }}
              className="ml-2 bg-green-500 rounded-full text-white py-2 px-3 w-full h-full"
            >
              <span className="px-3 text-white font-semibold">Apply</span>
            </button>
          </div>
          <button
            className="absolute -top-12 right-3 rounded-full shadow-md"
            onClick={handleSortClose}
          >
            <div className=" bg-white rounded-full p-2">
              <MdClose size={ICON_SIZE.SMALL} />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
