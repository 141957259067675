import { Link } from 'react-router-dom';

export default function OrderListItem({ order, id }) {
  const { orderNo } = order;
  return (
    <Link to={`/order/${id}`}>
      <div className="w-full px-4 py-2 border rounded-lg border-gray-500 flex flex-col gap-2 whitespace-pre">
        <div className="flex flex-row justify-start">
          <h2>Order Number :</h2>
          <h2 className="font-semibold">{`  ${orderNo}`}</h2>
        </div>
        <div className="flex flex-row">
          <h2 className="font-normal">{`Order date        :`}</h2>
          <h2 className="font-semibold font-sans">
            {' '}
            {` ${new Date(order.Date).toLocaleDateString()}`}
          </h2>
        </div>
      </div>
    </Link>
  );
}
