function getBgColor(variant, disabled) {
  if (disabled) {
    return 'bg-gray-500';
  }
  if (variant === 'outlined') {
    return 'bg-[#5AAE61]';
  }
  return 'bg-green-700';
}
export default function ButtonPrimary({
  handleClick,
  children,
  variant,
  disabled,
}) {
  const bgColor = getBgColor(variant, disabled);

  const variantStyle =
    variant === 'outlined' ? 'rounded-lg w-full h-full' : 'rounded-full';

  const style =
    bgColor + ' ' + variantStyle + ' text-white font-semibold px-2 py-1';
  const width = variant === 'outlined' ? {} : { width: 92.3 };

  return (
    <button
      onClick={() => {
        handleClick();
      }}
      style={width}
      className={style}
    >
      {children}
    </button>
  );
}
