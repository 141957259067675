import React, { useContext, useEffect } from 'react';
import { Spacer } from '../spacer';
import { CgSpinner } from 'react-icons/cg';
import { useState } from 'react';
import {
  LAST_DELETED_KEY,
  MESSAGE,
  ORDERS_DATA,
  ORDERS_HISTORY,
  ORDER_RESPONSE,
  USER_DATA,
} from '../../constants';
import CartItem from './cartItem';
import {
  addItem,
  calculateTotal,
  formattedDate,
  parseOrderKey,
  processOrder,
} from '../../utils/group';
import { toast } from 'react-hot-toast';
import EmptyCart from './emptyCart';
import Wordmark from '../WordMark';
import OrderSucess from './orderSucess';
import { useNavigate } from 'react-router-dom';
import { ACTIONS, DataDispatchContext } from '../../contexts/dataContext';

function Cart({ setSelected }) {
  const [loading2, setLoading2] = useState(false);
  const user = JSON.parse(localStorage.getItem(USER_DATA));
  const [data, setData] = useState({
    Date: new Date().toString(),
    Name: user?.name,
    Phone: user?.phone,
    Address: user?.address,
    Orders: {},
  });
  const [ordersUnparsed, setOrdersUnparsed] = useState({});
  const [total, setTotal] = useState(0);
  const [orderSucess, setOrderSucess] = useState(false);
  const navigate = useNavigate('/');
  const dispatch = useContext(DataDispatchContext);

  useEffect(() => {
    dispatch({ type: ACTIONS.NAVBAR_INDEX, payload: 3 });
    const Orders = processOrder(JSON.parse(localStorage.getItem(ORDERS_DATA)));
    const newOrdersUnparsed = JSON.parse(localStorage.getItem(ORDERS_DATA));

    setData((d) => ({
      ...d,
      Orders,
    }));

    setOrdersUnparsed(newOrdersUnparsed);
    setTotal(calculateTotal(newOrdersUnparsed));

    // console.log('data', data);
  }, [
    JSON.stringify(data),
    JSON.stringify(ordersUnparsed),
    JSON.stringify(user),
  ]);

  const itemRemoveWarning = () => {
    const lastKey = localStorage.getItem(LAST_DELETED_KEY);
    const lastOrder = parseOrderKey(lastKey);

    toast.success(
      (t) => (
        <span className="bg-white text-xs flex flex-row items-center">
          <p className="max-w-[9em] truncate mr-1">{lastOrder.name}</p>
          <p> removed succesfully</p>
          <button
            className="ml-2 p-1 border border-red-500 rounded-md"
            onClick={() => {
              addItem(lastKey, setOrdersUnparsed);
              toast.dismiss(t.id);
            }}
          >
            <b>Undo</b>
          </button>
        </span>
      ),
      {
        position: 'bottom-center',
        style: {
          position: 'relative',
          top: 'unset',
          bottom: '80px',
        },
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem(USER_DATA));

    if (!user) {
      navigate('/auth/login');
      return;
    }

    if (Object.keys(data.Orders).length === 0) {
      toast.error(MESSAGE.EMPTY_ORDER, { id: 'empty order error' });
      return;
    }

    setLoading2(true);

    try {
      await fetch(process.env.REACT_APP_SCRIPT_URL, {
        redirect: 'follow',
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((resData) => {
          setLoading2(false);
          const { status, orderNo } = resData;

          if (status === ORDER_RESPONSE.SUCCESS) {
            const oldOrderHistory = JSON.parse(
              localStorage.getItem(ORDERS_HISTORY)
            );
            const updatedOrderHistory = {
              [new Date(data.Date).getTime()]: { ...data, orderNo },
              ...oldOrderHistory,
            };
            localStorage.setItem(
              ORDERS_HISTORY,
              JSON.stringify(updatedOrderHistory)
            );

            setData((d) => ({
              ...d,
              Orders: {},
            }));

            localStorage.setItem(ORDERS_DATA, JSON.stringify({}));

            setOrderSucess(true);
            setTimeout(() => {
              navigate('/');
            }, 5000);
            // toast.success(MESSAGE.SUCCESSFUL_ORDER, {id: 'success'});
          } else {
            toast.error(MESSAGE.ERROR_ORDER, { id: 'order failed' });
          }
        });
    } catch (error) {
      setLoading2(false);
      toast.error(MESSAGE.ERROR_ORDER, { id: 'cart error' });
    }
  };

  if (orderSucess) {
    return <OrderSucess />;
  }

  return (
    <div className="flex flex-col overflow-scroll justify-start no-scrollbar">
      <div className="flex flex-col justify-start">
        <div className="sticky top-0 bg-white px-4">
          <Spacer y={4} />
          <Wordmark />
          <Spacer y={4} />
          <h1 className="font-semibold text-xl">Order Summary</h1>
          <Spacer y={2} />
          <hr className="border-1 border-gray-500 mb-2" />

          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <span>{data.Name}</span>
              <span>{data.Address}</span>
            </div>
            <div>
              Date:{' '}
              <span className="font-bold">
                {formattedDate(new Date(data.Date))}
              </span>
            </div>
          </div>
          <hr className="border-1 border-gray-500 mt-2" />
        </div>
        <div className="overflow-scroll px-4">
          {Object.keys(data.Orders).length === 0 ? (
            <EmptyCart />
          ) : (
            <div className="mt-2 flex flex-col gap-2 overflow-scroll no-scrollbar">
              {ordersUnparsed &&
                Object.keys(ordersUnparsed).map((item, index) => {
                  return (
                    <CartItem
                      key={index}
                      item={item}
                      orders={ordersUnparsed}
                      setOrdersUnparsed={setOrdersUnparsed}
                      itemRemoveWarning={itemRemoveWarning}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
      {/* <hr className="border-1 border-gray-500 mt-6" /> */}

      <div className="w-full border-none shadow-none sticky bottom-0 bg-white">
        {/* Total Value */}
        {/* <hr className="border-1 border-gray-500 mt-6" /> */}
        {/* <div className="flex flex-row justify-between my-3 px-2">
          <h2 className="font-bold">Total Bill</h2>
          <h2 className="font-bold">{total}/-</h2>
        </div>
        <hr className="border-1 border-gray-500" /> */}

        <button
          className="bg-[#d67c79] mt-3 w-11/12 flex gap-1 items-center justify-center py-2.5 text-white rounded mx-auto"
          onClick={handleSubmit}
        >
          {loading2 && <CgSpinner size={20} className="mt-1 animate-spin" />}
          <span>{user === null ? 'Signup to Continue' : 'Place order'}</span>
        </button>
        <Spacer y={4} />
      </div>
    </div>
  );
}

export default Cart;
