import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DATA_FIELDS, ORDERS_DATA, PLACEHOLDER_IMAGE } from '../../constants';
import {
  addItem,
  createKey,
  findProductByID,
  subtractItem,
} from '../../utils/group';
import Counter from '../counter';
import ButtonAddProduct from '../button/buttonAddProduct';
import Price from '../price';
import Description from './description';
import NavigationPanel from '../search/navigationPanel';
import { DataContext } from '../../contexts/dataContext';
import Divider from '../divider';

export default function ProductPage() {
  const [variant, setVariant] = useState({});
  const [orderKey, setOrderKey] = useState('');
  const [orders, setOrders] = useState(null);
  const location = useLocation();
  let product = location?.state?.product;

  const { id } = useParams();

  if (!product || Object.keys(product).length === 0) {
    // console.log(id);
    product = findProductByID(id);
  }

  // console.log(product, variant);

  const { bulkMode } = useContext(DataContext);

  useEffect(() => {
    // console.log('product', product);
    // console.log(variant, variant === product.variants[0]);
    if (!Object.keys(variant).length) {
      setVariant(product?.variants?.[0]);
    }

    setOrderKey(createKey(variant, bulkMode));

    if (localStorage.getItem(ORDERS_DATA) !== null) {
      let localOrders = JSON.parse(localStorage.getItem(ORDERS_DATA));
      setOrders(localOrders);
    }

    if (!product.variants) {
      setVariant(product);
    }
  }, [JSON.stringify(orders), JSON.stringify(variant), orderKey, bulkMode]);

  const handleClick = (item) => {
    setVariant(item);
    setOrderKey(createKey(variant, bulkMode));
  };

  // console.log(JSON.stringify(product.variants[0]), JSON.stringify(variant));
  return (
    <div id="product-page" className="flex flex-col justify-between h-full">
      <div>
        <div className="p-2 h-16 sticky top-0">
          <NavigationPanel showGrid={false} />
        </div>
        <Divider />
        <div className="h-64 relative">
          <img
            className="w-full h-full object-contain"
            src={
              (variant ? variant[DATA_FIELDS.IMG] : product[DATA_FIELDS.IMG]) ||
              PLACEHOLDER_IMAGE
            }
            alt="product"
          />
        </div>
        <div className="w-full flex justify-center">
          <div className="my-3 flex overflow-scroll gap-2 no-scrollbar whitespace-nowrap">
            {product.variants &&
              product.variants.map((item, index) => {
                if (
                  item[DATA_FIELDS.QUANTITY] === variant[DATA_FIELDS.QUANTITY]
                ) {
                  return (
                    <button
                      key={index}
                      onClick={() => setVariant(item)}
                      className="bg-[#386B26] text-white border rounded-full px-6 py-1 h-10 w-auto"
                    >
                      <span>
                        {item[DATA_FIELDS.QUANTITY].trim() === ''
                          ? `Variant no. ${index + 1}`
                          : item[DATA_FIELDS.QUANTITY].trim()}
                      </span>
                    </button>
                  );
                }
                return (
                  <button
                    key={index}
                    onClick={() => handleClick(item)}
                    className="border-[#386B26] border rounded-full px-6 py-1 h-10"
                  >
                    <span>
                      {item[DATA_FIELDS.QUANTITY].trim() === ''
                        ? `Variant no. ${index}`
                        : item[DATA_FIELDS.QUANTITY].trim()}
                    </span>
                  </button>
                );
              })}
          </div>
        </div>
        <hr className="border-1 border-gray-500 mb-2" />
        <div className="pl-2">
          <h2 className="font-bold">
            {variant[DATA_FIELDS.PRODUCT_NAME]} ({variant[DATA_FIELDS.QUANTITY]}
            )
          </h2>
          <span className="font-bold">
            <Price price={variant[DATA_FIELDS.PRICE]} />
          </span>
        </div>
        <hr className="border-1 border-gray-500 my-2" />
        <p className="pl-2 font-bold">Description</p>
        <div className="pl-2">
          <Description description={variant[DATA_FIELDS.DESCRIPTION]} />
        </div>
      </div>

      <div className="mt-3 py-3 flex flex-row justify-center px-4 gap-3">
        <button className="rounded-lg border border-gray-500 w-1/2 py-2">
          <Link to="/cart">
            <p className="px-3 text-gray-500 font-semibold">Your Cart</p>
          </Link>
        </button>
        <div className="w-1/2 flex flex-row justify-center items-center">
          {Object.keys(variant).length === 0 ||
          localStorage.getItem(ORDERS_DATA) === null ||
          !(orderKey in orders) ? (
            <ButtonAddProduct
              orderKey={orderKey}
              order={variant}
              setOrders={setOrders}
              variant={'outlined'}
            />
          ) : (
            <Counter
              value={orders[orderKey]}
              handleAdd={() => addItem(orderKey, setOrders)}
              handleSubtract={() => subtractItem(orderKey, setOrders)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
