import { useContext, useState } from 'react';
import { Spacer } from '../spacer';
import { Link } from 'react-router-dom';
import {
  DATA_FIELDS,
  GRID_SIZE,
  OFFERS_ACTIVE,
  ORDERS_DATA,
  PLACEHOLDER_IMAGE,
  SCROLL_TOP,
} from '../../constants';
import QuantityModal from '../modals/quantityModal';
import { addItem, createKey, subtractItem } from '../../utils/group';
import Counter from '../counter';
import ButtonAddProduct from '../button/buttonAddProduct';
import Price from '../price';
import BoxClosed from '../BoxClosed';
import { DataContext } from '../../contexts/dataContext';
import QuantitySelect from './quantitySelect';

export function SearchItem({
  product,
  index,
  modalIndex,
  setModalIndex,
  scrollTop = 0,
}) {
  const [open, setOpen] = useState(false);
  const { variants } = product;
  const [orders, setOrders] = useState({});

  let { bulkMode, gridSize } = useContext(DataContext);

  const offersActive = JSON.parse(localStorage.getItem(OFFERS_ACTIVE));
  const variantShadowStyle = variants?.length > 1 ? 'shadow-md' : '';

  if (offersActive) {
    bulkMode = true;
  }

  const handleOpen = () => {
    setOpen(true);
    setModalIndex(index);
  };
  const handleClose = () => setOpen(false);

  const firstItem = variants ? variants[0] : product;
  const name = variants ? product.name : product[DATA_FIELDS.PRODUCT_NAME];

  // console.log(firstItem[DATA_FIELDS.VARIANT])
  const orderKey = createKey(firstItem, bulkMode);
  const textStyle = `text-${
    gridSize === GRID_SIZE.GRID_3X3 ? 'sm' : 'base'
  } truncate`;
  const heightStyle = `${GRID_SIZE.GRID_3X3 ? 'h-36' : 'h-52'} px-1`;

  return (
    <div className={`border border-gray-400 flex flex-col pt-1 pb-2 bg-white`}>
      <div className={heightStyle}>
        <Link
          to={`/product/${firstItem[DATA_FIELDS.VARIANT]}`}
          state={{
            product,
          }}
          onClick={() => {
            localStorage.setItem(SCROLL_TOP, scrollTop);
          }}
        >
          <div className="flex justify-center h-full">
            <img
              className="w-full h-full object-contain"
              src={firstItem[DATA_FIELDS.IMG] || PLACEHOLDER_IMAGE}
              alt="product"
            />
          </div>
        </Link>
      </div>
      <div className="flex flex-col mt-2 mx-1 justify-between grow">
        <div>
          <p className={textStyle}>{name}</p>
          <div className={variantShadowStyle}>
            <QuantitySelect
              handleOpen={handleOpen}
              textStyle={textStyle}
              firstItem={firstItem}
              variants={variants}
            />
          </div>

          {/* price element */}
          <div className="flex flex-row flex-start mt-2 font-semibold text-sm ml-1">
            {offersActive && (
              <span className="mr-3">
                {' '}
                <BoxClosed />{' '}
              </span>
            )}
            <Price price={firstItem[DATA_FIELDS.PRICE]} />
          </div>
          <Spacer y={2} />
        </div>

        {/* add or remove product */}
        <div>
          {localStorage.getItem(ORDERS_DATA) === null ||
          !(orderKey in orders) ? (
            <div className="flex flex-row justify-center">
              <ButtonAddProduct
                orderKey={orderKey}
                order={firstItem}
                setOrders={setOrders}
              />
            </div>
          ) : (
            <Counter
              value={orders[orderKey]}
              handleAdd={() => addItem(orderKey, setOrders)}
              handleSubtract={() => subtractItem(orderKey, setOrders)}
            />
          )}
        </div>
      </div>
      <QuantityModal
        open={open}
        variants={variants}
        handleClose={handleClose}
        orders={orders}
        setOrders={setOrders}
        bulkMode={bulkMode}
        modalIndex={modalIndex}
        index={index}
      />
    </div>
  );
}
