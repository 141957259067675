export default function Description({ description }) {
  if (description === '' || !description) {
    return <></>;
  }

  const points = description.split(',');

  return (
    <ul className="pl-3 list-disc">
      {points.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}
