import { Route, Routes } from 'react-router-dom';
import Search from './search/search';
import Navbar from './navbar';
import Explore from './dashboard/explore';
import ProductPage from './product/product';
import CartPage from './cart/cart';
import Offers from './offers/Offers';
import DataProvider from '../contexts/dataContext';
import OrdersPage from './orders/OrdersPage';
import OrderDetails from './orders/orderDetails';
import Login from './auth/login';

export default function Home() {
  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <DataProvider>
          <Routes>
            <Route path="/" exact element={<Explore />} />
            <Route path="/search" exact element={<Search />} />
            <Route path="/offers" exact element={<Offers />} />
            <Route path="/product/:id" exact element={<ProductPage />} />
            <Route path="/orders" exact element={<OrdersPage />} />
            <Route path="/order/:id" element={<OrderDetails />} />
            <Route path="/cart" exact element={<CartPage />} />
            <Route path="/auth/login" exact element={<Login />} />
          </Routes>
          <Navbar />
        </DataProvider>
      </div>
    </>
  );
}
