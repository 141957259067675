import { useParams } from 'react-router-dom';
import { ORDERS_HISTORY } from '../../constants';
import Wordmark from '../WordMark';
import { Spacer } from '../spacer';
import NavigationPanel from '../search/navigationPanel';
import { ungroupOrderType } from '../../utils/group';
import { useEffect, useState } from 'react';

export default function OrderDetails() {
  const { id } = useParams();
  let ordersData = JSON.parse(localStorage.getItem(ORDERS_HISTORY));
  const [orders, setOrders] = useState([]);
  const [orderDate, setOrderDate] = useState('');
  const [orderNo, setOrderNo] = useState(0);
  const ordersString = JSON.stringify(orders);

  useEffect(() => {
    if (ordersData && id in ordersData) {
      setOrderDate(ordersData[id].Date);
      setOrderNo(ordersData[id].orderNo);
      const res = ungroupOrderType(ordersData[id].Orders);
      setOrders(res);
    }
  }, [ordersString, id]);

  return (
    <div className="w-11/12 mx-auto h-full flex flex-col overflow-scroll no-scrollbar">
      <div className="sticky top-0 bg-white py-4">
        <div className="fixed">
          <NavigationPanel />
        </div>
        <Wordmark />
        <Spacer y={6} />
        <div className="flex flex-row justify-between">
          <span>
            Order Number: <span className="font-semibold">{orderNo}</span>
          </span>
          <span>
            Order Date:{' '}
            <span className="font-semibold">
              {orderDate
                ? new Date(orderDate).toLocaleDateString()
                : new Date().toLocaleDateString()}
            </span>
          </span>
        </div>
        <Spacer y={2} />
        <hr className="border-1 border-gray-500 mb-2" />
      </div>
      <div className="h-full border-gray-500 border rounded-md overflow-scroll no-scrollbar">
        <div className="flex flex-col gap-1 mt-3">
          {orders &&
            orders.map((item, idx) => (
              <div
                key={`${item.name} + ${item.quantity}`}
                className="flex flex-row justify-between px-6 py-1 rounded-md"
              >
                <span>{`${idx + 1}. ${item.name}`}</span>
                <span>{item.quantity}</span>
              </div>
            ))}
        </div>
      </div>
      <Spacer y={4} />
    </div>
  );
}
