import { toast } from 'react-hot-toast';
import { LAST_DELETED_KEY, PLACEHOLDER_IMAGE } from '../../constants';
import {
  addItem,
  extractNumber,
  parseOrderKey,
  subtractItem,
} from '../../utils/group';
import Counter from '../counter';
import { Link } from 'react-router-dom';

export default function CartItem({
  item,
  orders,
  setOrdersUnparsed,
  itemRemoveWarning,
}) {
  let order = parseOrderKey(item);
  // console.log('order item', item);

  return (
    <div className="px-1 py-1 flex flex-row justify-between items-center border border-gray-400">
      <div className="ml-1 flex flex-col justify-center overflow-clip">
        <Link to={`/product/${order.name}`} state={{}}>
          <p className="font-semibold text-sm">{order.name}</p>
        </Link>
        <span className="font-semibold text-xs">{order.unit}</span>
        <p className="font-semibold text-xs">
          ₹ {extractNumber(order.price)}/-
        </p>
      </div>
      <div className="min-w-[8em]">
        <Counter
          value={`${orders[item]} ${order.quantity}`}
          handleAdd={() => {
            addItem(item, setOrdersUnparsed);
          }}
          handleSubtract={() => {
            const { lastItem, key } = subtractItem(item, setOrdersUnparsed);
            localStorage.setItem(LAST_DELETED_KEY, key);

            if (lastItem) {
              itemRemoveWarning();
            }
          }}
        />
      </div>
    </div>
  );
}
