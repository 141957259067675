import { useEffect, useState } from 'react';
import { SEARCH_SUBCATEGORY, SUBCATEGORIES_DATA } from '../../constants';

const paddingButton = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 12,
  paddingBottom: 12,
};

export function Subcategories({ category, subcategory, setSubcategory }) {
  const [subcategories, setSubcategories] = useState(['all']);

  useEffect(() => {
    // console.log(category);
    let subCategoriesMap = JSON.parse(localStorage.getItem(SUBCATEGORIES_DATA));

    if (category !== '' && category in subCategoriesMap) {
      // console.log(['All', ...subCategoriesMap[category]]);
      let localSubcategory = subCategoriesMap[category];
      localSubcategory = localSubcategory.sort();

      setSubcategories(['All', ...localSubcategory]);
      // console.log(subCategoriesMap, category, subcategories);
    }
  }, [category]);

  const handleClick = (item) => {
    localStorage.setItem(SEARCH_SUBCATEGORY, item);
    setSubcategory(item);
  };

  return (
    <div className="mt-4">
      <h1 className="font-semibold text-sm text-gray-800 ml-1">
        Product Types{' '}
      </h1>
      <div className="mt-2 flex flex-row whitespace-nowrap overflow-auto scroll-smooth no-scrollbar">
        {subcategories.map((item, index) => {
          if (subcategory?.toLowerCase() === item?.toLowerCase()) {
            return (
              <button
                style={paddingButton}
                className="!mr-2 bg-green-500 text-white border rounded-lg"
                key={index}
                onClick={() => handleClick(item)}
              >
                <p className="font-semibold text-sm min-w-[3rem]">{item}</p>
              </button>
            );
          }
          return (
            <button
              style={paddingButton}
              className="!mr-2 border-black border px-2 py-3 rounded-lg"
              key={index}
              value={item}
              onClick={() => handleClick(item)}
            >
              <p className="font-semibold text-sm min-w-[3rem]">{item}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
}
