export default function EmptyCart() {
  return (
    <div className="flex flex-col justify-center text-center h-32">
      <h2 className="font-bold">Your cart is empty.</h2>
      <p className="text-sm">
        Looks like you have not added anything to your cart. Go ahead and
        explore top categories.
      </p>
    </div>
  );
}
