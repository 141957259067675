import { Toaster } from 'react-hot-toast';
import Home from './components/home';

const App = () => {
  return (
    <section className="h-full">
      <div className="h-full flex flex-col justify-center">
        <Toaster
          toastOptions={{
            duration: 4000,
            style: { position: 'relative', top: 'env(safe-area-inset-top)' },
          }}
        />
        <Home />
      </div>
    </section>
  );
};

export default App;
