import { MdClose } from 'react-icons/md';
import { DATA_FIELDS, ICON_SIZE, ORDERS_DATA } from '../../constants';
import { useEffect } from 'react';
import {
  addItem,
  createKey,
  parseOrderKey,
  subtractItem,
} from '../../utils/group';
import { toast } from 'react-hot-toast';
import Counter from '../counter';
import ButtonAddProduct from '../button/buttonAddProduct';

export default function QuantityModal({
  open,
  handleClose,
  variants,
  orders,
  setOrders,
  bulkMode,
  modalIndex,
  index,
}) {
  // console.log(variants);

  useEffect(() => {
    if (localStorage.getItem(ORDERS_DATA) !== null) {
      let localOrders = JSON.parse(localStorage.getItem(ORDERS_DATA));
      setOrders(localOrders);
    }
  }, []);

  if (!open || modalIndex !== index) {
    return <></>;
  }

  return (
    <div>
      <div onClick={() => handleClose()} className="backdrop z-0" />
      <div
        style={{ translate: '0rem -3.5rem' }}
        className="bg-white fixed bottom-0 left-0  w-full drop-shadow-2xl rounded-t-2xl"
      >
        <div>
          <h1
            className="pt-6 pl-4 font-bold"
            variant="subtitle1"
            component="h2"
          >
            Choose Size
          </h1>
          <div className="w-11/12 my-4 ios-safe-bottom mx-auto">
            {variants.map((item, index) => {
              let orderKey = createKey(item, bulkMode);
              return (
                <div
                  className="flex flex-row justify-between items center w-full m-0 px-3 border border-gray-400 mt-2 py-1"
                  key={index}
                >
                  <div className="flex flex-row items-center">
                    <div>
                      <p>{item[DATA_FIELDS.QUANTITY]}</p>
                      <p className="font-semibold">
                        ₹ {item[DATA_FIELDS.PRICE]}
                      </p>
                    </div>
                    {bulkMode && (
                      <div className="ml-3">
                        <img src="/BoxClosed.png" alt="Box Closed icon." />
                      </div>
                    )}
                  </div>
                  <div className="flex items-center">
                    {localStorage.getItem(ORDERS_DATA) === null ||
                    !(orderKey in orders) ? (
                      <ButtonAddProduct
                        orderKey={orderKey}
                        setOrders={setOrders}
                        bulkMode={bulkMode}
                        order={item}
                      />
                    ) : (
                      <Counter
                        value={orders[orderKey]}
                        handleAdd={() => addItem(orderKey, setOrders)}
                        handleSubtract={() => subtractItem(orderKey, setOrders)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <button
            className="absolute -top-12 right-3 rounded-full shadow-md"
            onClick={handleClose}
          >
            <div className=" bg-white rounded-full p-2">
              <MdClose size={ICON_SIZE.SMALL} />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
