export default function BoxClosed() {
  return (
    <svg
      className="play"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.52397 7.05802L0 3.52887V16.3622L8.52397 19.9983V7.05802Z"
        fill="#E6A352"
      />
      <path
        d="M19.998 3.52887L8.52344 7.05802V19.9983L19.998 16.3622V3.52887Z"
        fill="#D59240"
      />
      <path
        d="M8.52397 7.05789L0 3.52873L5.24552 1.81763L13.7695 5.45373L8.52397 7.05789Z"
        fill="#E6A453"
      />
      <path
        d="M13.7706 5.45427L5.24658 1.81817L11.2571 0.00012207L19.9996 3.52928L13.7706 5.45427Z"
        fill="#E6A453"
      />
      <path
        d="M8.52397 7.05783L0 3.52867L5.24552 1.81757L13.7695 5.45366L8.52397 7.05783Z"
        fill="#EDC28E"
      />
      <path
        d="M13.7706 5.45427L5.24658 1.81817L11.2571 0.00012207L19.9996 3.52928L13.7706 5.45427Z"
        fill="#E8B06D"
      />
    </svg>
  );
}
